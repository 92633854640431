@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* You can add global styles to this file, and also import other style files */

body
{
	font-family: 'Open Sans', sans-serif;
}

.fixed
{
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.gradient {


	background: #c10f41;
	xbackground: -webkit-gradient(linear, left top, right top, color-stop(0%, transparent), color-stop(50%,#fff), color-stop(100%,transparent)); /* Chrome, Safari4+ */ /* Chrome10+, Safari5.1+ */    /* FF3.6+ */
	background: linear-gradient(-29deg, #c10f41 0%, #233b88 100%);      /* W3C */
	opacity: 0.9;
}

.background {
	background-color: black;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	color: white;
}



*
{
	color: white;
}

a {
	padding: 0;
	margin: 5px;
}

a:hover{
	-moz-border-radius-topleft: 10px;
	-moz-border-radius-topright: 10px;
	-moz-border-radius-bottomright: 10px;
	-moz-border-radius-bottomleft: 10px;
	border-radius: 10px 10px 10px 10px;
	background-color: white;
  }

.custom-toggler .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1.0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }

  .overlay
  {
	  background: #c10f41;
	  xbackground: -webkit-gradient(linear, left top, right top, color-stop(0%, transparent), color-stop(50%,#fff), color-stop(100%,transparent)); /* Chrome, Safari4+ */ /* Chrome10+, Safari5.1+ */    /* FF3.6+ */
	  background: linear-gradient(-29deg, #c10f41 0%, #233b88 100%);      /* W3C */
	  z-index: -1;
	  /* opacity: 0.9; */
  }

  .showMenu
  {
	background: #c10f41;
	xbackground: -webkit-gradient(linear, left top, right top, color-stop(0%, transparent), color-stop(50%,#fff), color-stop(100%,transparent)); /* Chrome, Safari4+ */ /* Chrome10+, Safari5.1+ */    /* FF3.6+ */
	background: linear-gradient(-29deg, #c10f41 0%, #233b88 100%);      /* W3C */
  }

.background {
	background-image: url('/img/background.jpg');
}

.uppercase {
	text-transform: uppercase;
}

.line
{
	width: 300px;
	border-bottom: 1px solid white;
	margin: 20px auto;
	background: red;
}

h1, h2
{
	font-weight: bold;
}

.heading
{
	font-weight: lighter;
	font-size: 2.8rem;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 8px;
	margin-top: 2rem;
	margin-bottom: 1rem;
}
